/**
 * Abstract class for New Relic integration.
 * New Relic is included via Cloudways.
 */
export class AbstractNewRelic {
	/**
	 * Log an info message to New Relic.
	 * If NR is not available it means that we are on the lower environments, so we log the message to the console.
	 *
	 * @param {string} message
	 */
	static logInfo( message ) {
		if ( Object.hasOwn( window, 'NREUM' ) && typeof window.NREUM.log === 'function' ) {
			window.NREUM.log( message, { level: 'debug' } );
		} else {
			// eslint-disable-next-line no-console
			console.log( message );
		}
	}

	/**
	 * Log an error to New Relic.
	 * If NR is not available it means that we are on the lower environments, so we log the error to the console.
	 *
	 * @param {Error} error
	 */
	static logError( error ) {
		if ( Object.hasOwn( window, 'NREUM' ) && typeof window.NREUM.noticeError === 'function' ) {
			window.NREUM.noticeError( error );
		} else {
			// eslint-disable-next-line no-console
			console.error( error );
		}
	}
}
