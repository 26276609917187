/**
 * External dependencies
 */
import { createInstance, OptimizelyDecideOption } from '@optimizely/optimizely-sdk';

/**
 * WordPress dependencies
 */
import { addAction, doAction } from '@wordpress/hooks';

/**
 * Internal dependencies
 */
import { AbstractNewRelic } from './newRelic';

export class OptimizelyFeatureExperimentation {
	constructor() {
		if ( ! Object.hasOwn( window, 'ofx' ) ) {
			window.ofx = {};
		}

		this.optimizely = null;
		this.userContext = null;
		this.userId = window.ofx?.userId || '';
		this.attributes = window.ofx?.userAttributes || undefined;
		this.sdkKey = window.ofx?.sdkKey || '';
		this.flags = window.ofx?.flags || {};
	}

	track( eventName = '', eventData = [] ) {
		if ( ! Object.hasOwn( window, 'ofx' ) || ! eventName || ! this.userContext ) {
			return;
		}

		const tags = {
			$opt_event_properties: eventData,
		};
		this.userContext.trackEvent( eventName, tags );
	}

	init() {
		this.doOptimizelyFX();
		addAction(
			'site_web_ofx_ready',
			'site-web',
			() => this.doFeatureFlags()
		);
	}

	doOptimizelyFX() {
		if ( ! this.sdkKey ) {
			AbstractNewRelic.logError( new Error( 'OptimizelyFX: sdkKey is required' ) );
			return;
		}

		this.optimizely = createInstance( {
			sdkKey: this.sdkKey,
		} );

		if ( ! this.optimizely ) {
			AbstractNewRelic.logInfo( 'OptimizelyFX client initialization unsuccessful' );
		} else {
			// Use optimizelyClient
			this.optimizely.onReady().then( ( { success, reason } ) => {
				if ( success ) {
					window.optimizelyFX = this.optimizely;

					// optimizelyClientInstance is ready to use.
					this.userContext = this.optimizely.createUserContext( this.userId, this.attributes );

					if ( ! this.userContext ) {
						AbstractNewRelic.logError( new Error( 'OptimizelyFX: failed to create user context' ) );
					}

					window.ofx = {
						...window.ofx,
						getUserContext: () => this.userContext,
						track: ( eventName = '', eventData = [] ) => this.track( eventName, eventData ),
					};

					doAction( 'site_web_ofx_ready' );
				} else {
					AbstractNewRelic.logInfo( `OptimizelyFX client initialization unsuccessful, reason: ${ reason }` );
				}
			} ).catch( ( error ) => {
				AbstractNewRelic.logError( error );
			} );
		}
	}

	doFeatureFlags() {
		if ( Object.hasOwn( window, 'ofx' ) && window.ofx.getUserContext() ) {
			const userContext = window.ofx.getUserContext();
			const decisionResults = userContext.decideAll( [ OptimizelyDecideOption.ENABLED_FLAGS_ONLY ] );

			const flags = {};
			Object.keys( decisionResults ).forEach( ( flagKey ) => {
				delete decisionResults[ flagKey ].userContext;

				flags[ flagKey ] = {
					...decisionResults[ flagKey ],
				};
			} );

			window.ofx = {
				...window.ofx,
				flags,
			};
		}
	}
}
