/**
 * WordPress dependencies
 */
import { addAction } from '@wordpress/hooks';

/**
 * Internal dependencies
 */
import { AbstractNewRelic } from './modules/newRelic';
import { OptimizelyFeatureExperimentation } from './modules/optimizelyFX';

async function OFXReady() {
	const flags = window.ofx?.flags;
	const userContext = window.ofx?.getUserContext();

	if ( ! flags && ! userContext ) {
		AbstractNewRelic.logError( new Error( 'Optimizely user context is not initialized' ) );
	}
}

document.addEventListener( 'DOMContentLoaded', async () => {
	const OptimizelyFX = new OptimizelyFeatureExperimentation();
	const FORM_INIT_DELAY = 300;
	const MODULE_INIT_DELAY = 150;
	const EVENT_MAPPINGS = {
		'fallback-zip': 'zip_fallback_clicks',
		'double-conditional-question-1': 'conditional_question_1_clicks',
		'double-conditional-question-2': 'conditional_question_2_clicks',
		'conditional-question': 'conditional_project_clicks',
		'dropdown-question': 'project_clicks',
		'project-details': 'enter_details_clicks',
		address: 'address_clicks',
		name: 'name_clicks',
		'final-step': 'form_submits',
		'child-leads-step-1': 'crosssell_confirm_clicks',
		'child-leads-step-2': 'crosssell_form_submits',
	};

	// Non-critical modules.
	setTimeout( () => {
		OptimizelyFX.init();
	}, 0 );

	addAction(
		'site_web_ofx_ready',
		'site-web',
		OFXReady,
		MODULE_INIT_DELAY
	);

	setTimeout( async () => {
		const formPages = document.querySelectorAll( '.form-page' );
		if ( formPages.length !== 0 ) {
			const tags = [];
			const category = document.querySelector( 'input[name="search_keyword"]' );
			const project = document.querySelector( 'input[name="incoming_project"]' );

			if ( category || project ) {
				if ( category ) {
					tags.push( { key: 'category', value: category.value } );
				}
				if ( project ) {
					tags.push( { key: 'project', value: project.value } );
				}
			}

			formPages.forEach( ( step ) => {
				const stepName = step.dataset.stepName;

				const handleButtonClick = ( btnStepName ) => {
					try {
						if ( EVENT_MAPPINGS[ btnStepName ] ) {
							OptimizelyFX.track( EVENT_MAPPINGS[ btnStepName ], tags );
						}
					} catch ( error ) {
						// AbstractNewRelic.logError( error );
						// eslint-disable-next-line no-console
						console.error( 'Failed to track event:', error );
					}
				};

				const nextButton = step.querySelector( '.submit__next' );
				if ( nextButton ) {
					nextButton.addEventListener( 'click', () => handleButtonClick( stepName ) );
				}

				const altButton = step.querySelector( '.submit__alt' );
				if ( altButton ) {
					altButton.addEventListener( 'click', () => handleButtonClick( stepName ) );
				}
			} );
		}
	}, FORM_INIT_DELAY );
} );
